import { type AuthenticationInformation, ConnectwareError, ConnectwareErrorType } from '../../domain'
import type { BrowserService, LoggerService } from '../../application'

export class HTML5BrowserService implements BrowserService {
    constructor (private readonly logger: LoggerService, private readonly origin: URL['origin'], private readonly cookiePaths: string[]) {}

    private setAuthCookie (token: string | null, expiresAt: Date | null, path: string): void {
        const values: ([key: string, value: string] | [key: string])[] = [
            ['X-Auth-Token', encodeURIComponent(token ?? '')],
            ['path', path],
            ['SameSite', 'strict'],
            ['Secure'],
            ['Host', this.origin],
        ]

        if (expiresAt) {
            values.push(['expires', expiresAt.toUTCString()])
        }

        document.cookie = values.map((pair) => pair.join('=')).join('; ')
    }

    private getUrlParameter (name: string): string | null {
        return window.location.search ? new URLSearchParams(window.location.search).get(name) : null
    }

    isInspecting (): boolean {
        return this.getUrlParameter('inspection') !== null
    }

    redirect (url: URL): void {
        window.location.replace(url.toString())
    }

    getRedirectionURL (): URL | null {
        const redirectParameter = this.getUrlParameter('redirect')
        return redirectParameter ? new URL(redirectParameter, this.origin) : null
    }

    getToken (): string | null {
        return this.getUrlParameter('apiToken')
    }

    authenticate (info: AuthenticationInformation | null): void {
        const token = info?.token ?? null
        const expiresAt = info?.expiresAt ?? null

        for (const path of this.cookiePaths) {
            this.setAuthCookie(token, expiresAt, path)
        }
    }

    reload (): void {
        window.location.reload()
    }

    open (url: URL, name: string | null): void {
        const ref = url.toString()
        window.open(ref, name || ref)
    }

    sendEmail (target: string, subject: string | null): void {
        const url = new URL(`mailto:${target}`)

        if (subject) {
            url.searchParams.append('subject', subject)
        }

        this.open(url, null)
    }

    copy (value: unknown): string | null {
        let data: string | null = null

        switch (typeof value) {
            case 'string':
                data = value
                break
            default:
                /** Just quietly log the issue */
                this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Only string values are supported to be copied', { value }))
        }

        if (data === null) {
            return null
        }

        navigator.clipboard.writeText(data).catch(({ message, code }: DOMException) => {
            /** Just quietly log the issue */
            this.logger.error(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not write to clipboard', { message, code, value }))
        })

        return data
    }
}
