import { ConnectwareError, type CybusResourceDeviation, type CybusService, type StatusType } from '../../../domain'
import type {
    DeviationFetcher,
    DeviationsSubscription,
    ResourceDeviationSupportedType,
    RSTAdapter,
    ServiceDeviationSupportedType,
    StatusSupportedType,
    StatusTypeFetcher,
    StatusTypeSubscription,
} from '.'

export class RSTAdapterMixin implements RSTAdapter {
    private static fetchCached<T> (retrieveCached: () => T | ConnectwareError | null, fetchData: () => Promise<T>): Promise<T> {
        const value = retrieveCached() ?? fetchData()
        return ConnectwareError.is(value) ? Promise.reject(value) : Promise.resolve(value)
    }

    constructor (
        private readonly statusTypeFetcher: StatusTypeFetcher,
        private readonly deviationFetcher: DeviationFetcher,
        private readonly statusTypeSubscription: StatusTypeSubscription,
        private readonly deviationsSubscription: DeviationsSubscription
    ) {}

    fetchIsDeviated (type: ServiceDeviationSupportedType, serviceId: CybusService['id']): Promise<boolean> {
        return RSTAdapterMixin.fetchCached(
            () => this.deviationsSubscription.useCachedIsDeviated(type, serviceId),
            () => this.deviationFetcher.fetchIsDeviated(type, serviceId)
        )
    }

    fetchResourceDeviations (type: ResourceDeviationSupportedType, serviceId: CybusService['id']): Promise<CybusResourceDeviation[]> {
        return this.deviationFetcher.fetchResourceDeviations(type, serviceId)
    }

    fetchStatusType (type: StatusSupportedType, resourceId: string): Promise<StatusType> {
        return RSTAdapterMixin.fetchCached(
            () => this.statusTypeSubscription.useCachedStatusType(type, resourceId),
            () => this.statusTypeFetcher.fetchStatusType(type, resourceId)
        )
    }

    subscribeToStatusType (type: StatusSupportedType, resourceId: string, handler: VoidFunction): Promise<VoidFunction> {
        return this.statusTypeSubscription.subscribeToStatusType(type, resourceId, handler)
    }

    subscribeToServiceDeviationCount (type: ServiceDeviationSupportedType, serviceId: CybusService['id'], handler: VoidFunction): Promise<VoidFunction> {
        return this.deviationsSubscription.subscribeToServiceDeviationCount(type, serviceId, handler)
    }
}
