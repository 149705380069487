import type { Mutable, NonUndefined, PickByValueExact, UnionToIntersection, ValuesType } from 'utility-types'

import type { ArrayType, OnlyDeepPrimitive, SubProperty } from '../../../utils'

import type { components as AuthServerComponents, paths as AuthServerPaths } from './auth-server'
import type { components as ContainerManagerComponents, paths as ContainerManagerPaths } from './container-manager'
import type { components as ProtocolMapperComponents, paths as ProtocolMapperPaths } from './protocol-mapper'
import type { components as ServiceManagerComponents, paths as ServiceManagerPaths } from './service-manager'
import type { components as SystemControlServerComponents, paths as SystemControlServerPaths } from './system-control-server'
import type { components as ResourceStatusTrackingComponents, paths as ResourceStatusTrackingPaths } from './resource-status-tracking'

export * from './Legacy'

type AllComponents = Readonly<{
    ['auth-server']: AuthServerComponents
    ['container-manager']: ContainerManagerComponents
    ['protocol-mapper']: ProtocolMapperComponents
    ['service-manager']: ServiceManagerComponents
    ['system-control-server']: SystemControlServerComponents
    ['resource-status-tracking']: ResourceStatusTrackingComponents
}>

export type ContainerDefinition<
    ContainerName extends keyof AllPaths,
    Property extends keyof AllComponents[ContainerName]['schemas']
> = AllComponents[ContainerName]['schemas'][Property]

type AllPaths = Readonly<{
    ['auth-server']: AuthServerPaths
    ['container-manager']: ContainerManagerPaths
    ['protocol-mapper']: ProtocolMapperPaths
    ['service-manager']: ServiceManagerPaths
    ['system-control-server']: SystemControlServerPaths
    ['resource-status-tracking']: ResourceStatusTrackingPaths
}>

export type ContainerPaths<ContainerName extends keyof AllPaths> = keyof AllPaths[ContainerName]

export type AllContainerPaths = keyof UnionToIntersection<ValuesType<AllPaths>>

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
 */
type SupportedHttpMethods = 'connect' | 'delete' | 'get' | 'head' | 'options' | 'patch' | 'post' | 'put' | 'trace'

/**
 * Very tricky type that traverses the paths children
 * Extracts from it only the ones that something of value (so, not never | undefined)
 * And only the ones that are valid http methods
 */
type RawHttpMethod<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> = Extract<
    keyof Omit<AllPaths[ContainerName][Path], keyof PickByValueExact<AllPaths[ContainerName][Path], never | undefined>>,
    SupportedHttpMethods
>

export type HttpMethod<C extends keyof AllPaths, P extends keyof AllPaths[C]> = Uppercase<RawHttpMethod<C, P>>

type StringifiedParameter<T> = T extends string | boolean | number ? `${T}` : T extends any[] | readonly any[] ? ArrayType<Mutable<T>>[] : never
type StringifiedParameters<T> = { [P in keyof T]: StringifiedParameter<T[P]> }

/**
 * For the path, get the parameters and then the query configuration
 * Finally, drop any sort optional setup (NonUndefined)
 */
type InternalParameters<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> = NonUndefined<
    SubProperty<SubProperty<AllPaths[ContainerName][Path][RawHttpMethod<ContainerName, Path>], 'parameters'>, 'query'>
>

export type QueryParameters<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> = StringifiedParameters<
    InternalParameters<ContainerName, Path>
>

type Responses<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> =
    /**
     * For the path, get the responses
     */
    SubProperty<AllPaths[ContainerName][Path][RawHttpMethod<ContainerName, Path>], 'responses'>

export type ResponseStatuses<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> =
    /**
     * For the path responses, get the keys (status codes) and make sure that they are numbers
     */
    Extract<keyof Responses<ContainerName, Path>, number>

type Response<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> =
    /**
     * For the path responses, get all the responses across all statuses
     */
    Responses<ContainerName, Path>[ResponseStatuses<ContainerName, Path>]

type ResponseContent<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> = SubProperty<Response<ContainerName, Path>, 'content'>

export type JsonResponseContent<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> =
    /**
     * Now extract from the responses, only the ones that are supposed to come in the json body
     */
    OnlyDeepPrimitive<SubProperty<ResponseContent<ContainerName, Path>, 'application/json'>>

export type EventStreamResponseContent<ContainerName extends keyof AllPaths, Path extends keyof AllPaths[ContainerName]> =
    /**
     * Now extract from the responses, only the ones that are supposed to come as websocket messages
     */
    OnlyDeepPrimitive<SubProperty<ResponseContent<ContainerName, Path>, 'text/event-stream'>>
