import { ConnectwareError, ConnectwareErrorType, StatusType } from '../../../domain'
import { mapToStatusType } from '..'

export const mapRSTResourceStatusViewId = (resource: Readonly<{ id: string }>): string => resource.id

export const mapRSTResourceStatusViewCurrentStatusType = (state: Readonly<{ current: string }>): StatusType => mapToStatusType(state.current)

export const createRSTNonDeviationsCountMapper =
    <V, T>(mapper: (value: Exclude<V, number>) => T): ((maybeCount: V) => T) =>
    (maybeCount) => {
        if (typeof maybeCount !== 'number') {
            return mapper(maybeCount as Exclude<V, number>)
        }

        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Server did not respond with service specific deviations count')
    }

/**
 * Maps out the status of a runner, a healthy runner is always online, otherwise offline
 */
export const mapRSTRunnerStatus = (healthy: boolean): StatusType => (healthy ? StatusType.ONLINE : StatusType.OFFLINE)
