import type { ReadonlyRecord } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusPermissionOperations } from '../../../domain'

import type { ContainerDefinition } from '..'

type PermissionOperation = ContainerDefinition<'auth-server', 'Operation'>

const operationsMap: ReadonlyRecord<PermissionOperation, CybusPermissionOperations> = {
    read: { read: true, write: false },
    write: { read: false, write: true },
    readWrite: { read: true, write: true },
}

export const mapResponseToOperations = (operation: PermissionOperation): CybusPermissionOperations => operationsMap[operation]
export const mapOperationsToResponse = ({ read, write }: CybusPermissionOperations): PermissionOperation => {
    if (!read && !write) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Can not map operations as it is invalid')
    }

    return read && write ? 'readWrite' : read ? 'read' : 'write'
}
