/** This script was auto generated by 'TypeValidatorCreator' at 'src/infrastructure/scripts/Typescript/TypeValidator.ts' **/
import { createValidator } from '../../../schema'
import type {
    ExpectedBooleanArrayField,
    ExpectedBooleanField,
    ExpectedIntegerArrayField,
    ExpectedIntegerField,
    ExpectedNumberArrayField,
    ExpectedNumberField,
    ExpectedStringArrayField,
    ExpectedStringField,
    ExpectedStringIndexField,
    MinimalDefinitionsFileSchema,
    MinimalExpectedServiceData,
    MinimalObjectSchema,
    MinimalRequiredBaseSchema,
    MinimalRequiredOrchestratingSchema,
} from './Types'

const MinimalRequiredBaseSchemaSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/MinimalRequiredBaseSchema',
    definitions: {
        MinimalRequiredBaseSchema: {
            type: 'object',
            properties: {
                properties: {
                    type: 'object',
                    properties: { metadata: {}, resources: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2Cunknown%3E' } },
                    required: ['metadata', 'resources'],
                },
            },
            required: ['properties'],
        },
        'ReadonlyRecord<string,unknown>': { type: 'object', additionalProperties: {} },
    },
}
export const isMinimalRequiredBaseSchema = createValidator<MinimalRequiredBaseSchema>(MinimalRequiredBaseSchemaSchema, 'isMinimalRequiredBaseSchema')
const MinimalExpectedServiceDataSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/MinimalExpectedServiceData',
    definitions: {
        MinimalExpectedServiceData: {
            type: 'object',
            properties: {
                resources: {
                    $ref: '#/definitions/ReadonlyRecord%3Cstring%2Calias-2073358172-73703-73815-2073358172-0-216469%3Cstructure-314669989-586-671-314669989-576-672-314669989-553-673-314669989-538-673-314669989-537-675-314669989-527-676-314669989-490-676-314669989-0-2991%3E%3E',
                },
                description: { type: 'string' },
                metadata: {
                    type: 'object',
                    properties: {
                        name: { type: 'string' },
                        icon: { type: 'string' },
                        provider: { type: 'string' },
                        homepage: { type: 'string' },
                        version: { type: 'string' },
                    },
                    required: ['name'],
                },
                parameters: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2Calias-813971708-1448-2353-813971708-0-21261%3E' },
            },
            required: ['description', 'metadata', 'resources'],
        },
        'ReadonlyRecord<string,alias-2073358172-73703-73815-2073358172-0-216469<structure-314669989-586-671-314669989-576-672-314669989-553-673-314669989-538-673-314669989-537-675-314669989-527-676-314669989-490-676-314669989-0-2991>>':
            {
                type: 'object',
                additionalProperties: {
                    type: 'object',
                    properties: {
                        type: { $ref: '#/definitions/CommissioningDataResourceType' },
                        properties: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2Cunknown%3E' },
                    },
                    required: ['type'],
                },
            },
        CommissioningDataResourceType: {
            type: 'string',
            enum: [
                'Cybus::Connection',
                'Cybus::Endpoint',
                'Cybus::Mapping',
                'Cybus::Container',
                'Cybus::Link',
                'Cybus::IngressRoute',
                'Cybus::User',
                'Cybus::Role',
                'Cybus::Volume',
                'Cybus::Server',
                'Cybus::Server::Opcua',
                'Cybus::Server::Http',
                'Cybus::Node',
            ],
        },
        'ReadonlyRecord<string,unknown>': { type: 'object', additionalProperties: {} },
        'ReadonlyRecord<string,alias-813971708-1448-2353-813971708-0-21261>': {
            type: 'object',
            additionalProperties: {
                anyOf: [
                    {
                        type: 'object',
                        properties: {
                            minLength: { type: 'number' },
                            maxLength: { type: 'number' },
                            pattern: { type: 'string' },
                            format: { type: 'string', enum: ['date', 'date-time', 'uri', 'email', 'hostname', 'ipv4', 'ipv6', 'regex'] },
                            type: { type: 'string', const: 'string' },
                            default: { type: 'string' },
                            description: { type: 'string' },
                            enum: { type: 'array', items: { type: 'string' } },
                        },
                        required: ['type'],
                    },
                    {
                        type: 'object',
                        properties: {
                            minimum: { type: 'number' },
                            maximum: { type: 'number' },
                            type: { type: 'string', enum: ['number', 'integer'] },
                            default: { type: 'number' },
                            description: { type: 'string' },
                            enum: { type: 'array', items: { type: 'number' } },
                        },
                        required: ['type'],
                    },
                    {
                        type: 'object',
                        properties: {
                            type: { type: 'string', const: 'boolean' },
                            default: { type: 'boolean' },
                            description: { type: 'string' },
                            enum: { type: 'array', items: { type: 'boolean' } },
                        },
                        required: ['type'],
                    },
                    {
                        type: 'object',
                        properties: {
                            items: {
                                anyOf: [
                                    { type: 'object', properties: { type: { type: ['string', 'number', 'boolean'] } }, required: ['type'] },
                                    {
                                        type: 'array',
                                        items: { type: 'object', properties: { type: { type: ['string', 'number', 'boolean'] } }, required: ['type'] },
                                    },
                                ],
                            },
                            minItems: { type: 'number' },
                            maxItems: { type: 'number' },
                            uniqueItems: { type: 'boolean' },
                            type: { type: 'string', const: 'array' },
                            default: { type: 'array', items: { type: ['string', 'number', 'boolean'] } },
                            description: { type: 'string' },
                            enum: { type: 'array', items: { type: 'array', items: { type: ['string', 'number', 'boolean'] } } },
                        },
                        required: ['items', 'type'],
                    },
                ],
            },
        },
    },
}
export const isMinimalExpectedServiceData = createValidator<MinimalExpectedServiceData>(MinimalExpectedServiceDataSchema, 'isMinimalExpectedServiceData')
const MinimalObjectSchemaSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/MinimalObjectSchema',
    definitions: {
        MinimalObjectSchema: {
            type: 'object',
            properties: {
                required: { type: 'array', items: { type: 'string' } },
                type: { type: 'string', const: 'object' },
                properties: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2Cunknown%3E' },
            },
            required: ['type'],
        },
        'ReadonlyRecord<string,unknown>': { type: 'object', additionalProperties: {} },
    },
}
export const isMinimalObjectSchema = createValidator<MinimalObjectSchema>(MinimalObjectSchemaSchema, 'isMinimalObjectSchema')
const MinimalRequiredOrchestratingSchemaSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/MinimalRequiredOrchestratingSchema',
    definitions: {
        MinimalRequiredOrchestratingSchema: {
            type: 'object',
            properties: {
                oneOf: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            properties: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2Cunknown%3E' },
                            required: { type: 'array', items: { type: 'string' } },
                        },
                        required: ['properties', 'required'],
                    },
                },
                allOf: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            if: {
                                type: 'object',
                                properties: {
                                    properties: {
                                        $ref: '#/definitions/ReadonlyRecord%3Cstring%2Calias-2073358172-73703-73815-2073358172-0-216469%3Cstructure-314669989-1197-1214-314669989-1187-1215-314669989-1164-1216-314669989-1152-1217-314669989-1151-1238-314669989-1133-1239-314669989-1104-1314-314669989-1082-1422-314669989-1032-1422-314669989-0-2991%3E%3E',
                                    },
                                    required: { type: 'array', items: { type: 'string' } },
                                },
                                required: ['properties', 'required'],
                            },
                            then: {
                                type: 'object',
                                properties: { properties: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2CRefSchema%3E' } },
                                required: ['properties'],
                            },
                        },
                        required: ['if', 'then'],
                    },
                },
                required: { type: 'array', items: { type: 'string' } },
                type: { type: 'string', const: 'object' },
                properties: { $ref: '#/definitions/ReadonlyRecord%3Cstring%2Cunknown%3E' },
            },
            required: ['allOf', 'type'],
        },
        'ReadonlyRecord<string,unknown>': { type: 'object', additionalProperties: {} },
        'ReadonlyRecord<string,alias-2073358172-73703-73815-2073358172-0-216469<structure-314669989-1197-1214-314669989-1187-1215-314669989-1164-1216-314669989-1152-1217-314669989-1151-1238-314669989-1133-1239-314669989-1104-1314-314669989-1082-1422-314669989-1032-1422-314669989-0-2991>>':
            { type: 'object', additionalProperties: { type: 'object', properties: { const: { type: 'string' } }, required: ['const'] } },
        'ReadonlyRecord<string,RefSchema>': { type: 'object', additionalProperties: { $ref: '#/definitions/RefSchema' } },
        RefSchema: { $ref: '#/definitions/ReadonlyRecord%3C%22%24ref%22%2Cstring%3E' },
        'ReadonlyRecord<"$ref",string>': { type: 'object', properties: { $ref: { type: 'string' } }, required: ['$ref'], additionalProperties: false },
    },
}
export const isMinimalRequiredOrchestratingSchema = createValidator<MinimalRequiredOrchestratingSchema>(
    MinimalRequiredOrchestratingSchemaSchema,
    'isMinimalRequiredOrchestratingSchema'
)
const MinimalDefinitionsFileSchemaSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/MinimalDefinitionsFileSchema',
    definitions: {
        MinimalDefinitionsFileSchema: {
            type: 'object',
            properties: {
                $id: { type: 'string' },
                definitions: {
                    $ref: '#/definitions/ReadonlyRecord%3Cstring%2Calias-2073358172-73495-73598-2073358172-0-216469%3Calias-314669989-871-940-314669989-0-2991%3Calias-314669989-163-220-314669989-0-2991%2Calias-314669989-163-220-314669989-0-2991%3E%3E%3E',
                },
            },
            required: ['$id', 'definitions'],
        },
        'ReadonlyRecord<string,alias-2073358172-73495-73598-2073358172-0-216469<alias-314669989-871-940-314669989-0-2991<alias-314669989-163-220-314669989-0-2991,alias-314669989-163-220-314669989-0-2991>>>':
            {
                type: 'object',
                additionalProperties: {
                    type: 'object',
                    properties: {
                        if: { type: 'object', properties: { required: { type: 'array', items: { type: 'string' } } } },
                        then: { type: 'object', properties: { required: { type: 'array', items: { type: 'string' } } } },
                    },
                },
            },
    },
}
export const isMinimalDefinitionsFileSchema = createValidator<MinimalDefinitionsFileSchema>(MinimalDefinitionsFileSchemaSchema, 'isMinimalDefinitionsFileSchema')
const ExpectedBooleanFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedBooleanField',
    definitions: {
        ExpectedBooleanField: {
            type: 'object',
            properties: {
                type: { type: 'string', const: 'boolean' },
                description: { type: 'string' },
                default: { type: 'boolean' },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cboolean%3E' },
                const: { type: 'boolean' },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'boolean' } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['type'],
            additionalProperties: false,
        },
        'NonEmptyArray<boolean>': {
            allOf: [
                { type: 'array', items: { type: 'boolean' }, minItems: 1 },
                { type: 'array', items: { type: 'boolean' } },
            ],
        },
    },
}
export const isExpectedBooleanField = createValidator<ExpectedBooleanField>(ExpectedBooleanFieldSchema, 'isExpectedBooleanField')
const ExpectedStringFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedStringField',
    definitions: {
        ExpectedStringField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                minLength: { type: 'number' },
                maxLength: { type: 'number' },
                pattern: { type: 'string' },
                type: { type: 'string', const: 'string' },
                description: { type: 'string' },
                default: { type: 'string' },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cstring%3E' },
                const: { type: 'string' },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'string' } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['type'],
        },
        'NonEmptyArray<string>': {
            allOf: [
                { type: 'array', items: { type: 'string' }, minItems: 1 },
                { type: 'array', items: { type: 'string' } },
            ],
        },
    },
}
export const isExpectedStringField = createValidator<ExpectedStringField>(ExpectedStringFieldSchema, 'isExpectedStringField')
const ExpectedIntegerFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedIntegerField',
    definitions: {
        ExpectedIntegerField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                minimum: { type: 'number' },
                maximum: { type: 'number' },
                type: { type: 'string', const: 'integer' },
                description: { type: 'string' },
                default: { type: 'number' },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cnumber%3E' },
                const: { type: 'number' },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'number' } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['type'],
        },
        'NonEmptyArray<number>': {
            allOf: [
                { type: 'array', items: { type: 'number' }, minItems: 1 },
                { type: 'array', items: { type: 'number' } },
            ],
        },
    },
}
export const isExpectedIntegerField = createValidator<ExpectedIntegerField>(ExpectedIntegerFieldSchema, 'isExpectedIntegerField')
const ExpectedNumberFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedNumberField',
    definitions: {
        ExpectedNumberField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                minimum: { type: 'number' },
                maximum: { type: 'number' },
                type: { type: 'string', const: 'number' },
                description: { type: 'string' },
                default: { type: 'number' },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cnumber%3E' },
                const: { type: 'number' },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'number' } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['type'],
        },
        'NonEmptyArray<number>': {
            allOf: [
                { type: 'array', items: { type: 'number' }, minItems: 1 },
                { type: 'array', items: { type: 'number' } },
            ],
        },
    },
}
export const isExpectedNumberField = createValidator<ExpectedNumberField>(ExpectedNumberFieldSchema, 'isExpectedNumberField')
const ExpectedStringArrayFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedStringArrayField',
    definitions: {
        ExpectedStringArrayField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                uniqueItems: { type: 'boolean' },
                items: {
                    type: 'object',
                    properties: { $id: { type: 'string' }, type: { type: 'string', const: 'string' }, title: { type: 'string' } },
                    required: ['type'],
                    additionalProperties: false,
                },
                type: { type: 'string', const: 'array' },
                description: { type: 'string' },
                default: { type: 'array', items: { type: 'string' } },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cstring%5B%5D%3E' },
                const: { type: 'array', items: { type: 'string' } },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'array', items: { type: 'string' } } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['items', 'type'],
        },
        'NonEmptyArray<string[]>': {
            allOf: [
                { type: 'array', items: { type: 'array', items: { type: 'string' } }, minItems: 1 },
                { type: 'array', items: { type: 'array', items: { type: 'string' } } },
            ],
        },
    },
}
export const isExpectedStringArrayField = createValidator<ExpectedStringArrayField>(ExpectedStringArrayFieldSchema, 'isExpectedStringArrayField')
const ExpectedNumberArrayFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedNumberArrayField',
    definitions: {
        ExpectedNumberArrayField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                uniqueItems: { type: 'boolean' },
                items: {
                    type: 'object',
                    properties: { $id: { type: 'string' }, type: { type: 'string', const: 'number' }, title: { type: 'string' } },
                    required: ['type'],
                    additionalProperties: false,
                },
                type: { type: 'string', const: 'array' },
                description: { type: 'string' },
                default: { type: 'array', items: { type: 'number' } },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cnumber%5B%5D%3E' },
                const: { type: 'array', items: { type: 'number' } },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'array', items: { type: 'number' } } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['items', 'type'],
        },
        'NonEmptyArray<number[]>': {
            allOf: [
                { type: 'array', items: { type: 'array', items: { type: 'number' } }, minItems: 1 },
                { type: 'array', items: { type: 'array', items: { type: 'number' } } },
            ],
        },
    },
}
export const isExpectedNumberArrayField = createValidator<ExpectedNumberArrayField>(ExpectedNumberArrayFieldSchema, 'isExpectedNumberArrayField')
const ExpectedIntegerArrayFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedIntegerArrayField',
    definitions: {
        ExpectedIntegerArrayField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                uniqueItems: { type: 'boolean' },
                items: {
                    type: 'object',
                    properties: { $id: { type: 'string' }, type: { type: 'string', const: 'integer' }, title: { type: 'string' } },
                    required: ['type'],
                    additionalProperties: false,
                },
                type: { type: 'string', const: 'array' },
                description: { type: 'string' },
                default: { type: 'array', items: { type: 'number' } },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cnumber%5B%5D%3E' },
                const: { type: 'array', items: { type: 'number' } },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'array', items: { type: 'number' } } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['items', 'type'],
        },
        'NonEmptyArray<number[]>': {
            allOf: [
                { type: 'array', items: { type: 'array', items: { type: 'number' } }, minItems: 1 },
                { type: 'array', items: { type: 'array', items: { type: 'number' } } },
            ],
        },
    },
}
export const isExpectedIntegerArrayField = createValidator<ExpectedIntegerArrayField>(ExpectedIntegerArrayFieldSchema, 'isExpectedIntegerArrayField')
const ExpectedBooleanArrayFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedBooleanArrayField',
    definitions: {
        ExpectedBooleanArrayField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                uniqueItems: { type: 'boolean' },
                items: {
                    type: 'object',
                    properties: { $id: { type: 'string' }, type: { type: 'string', const: 'boolean' }, title: { type: 'string' } },
                    required: ['type'],
                    additionalProperties: false,
                },
                type: { type: 'string', const: 'array' },
                description: { type: 'string' },
                default: { type: 'array', items: { type: 'boolean' } },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cboolean%5B%5D%3E' },
                const: { type: 'array', items: { type: 'boolean' } },
                $id: { type: 'string' },
                title: { type: 'string' },
                examples: { type: 'array', items: { type: 'array', items: { type: 'boolean' } } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['items', 'type'],
        },
        'NonEmptyArray<boolean[]>': {
            allOf: [
                { type: 'array', items: { type: 'array', items: { type: 'boolean' } }, minItems: 1 },
                { type: 'array', items: { type: 'array', items: { type: 'boolean' } } },
            ],
        },
    },
}
export const isExpectedBooleanArrayField = createValidator<ExpectedBooleanArrayField>(ExpectedBooleanArrayFieldSchema, 'isExpectedBooleanArrayField')
const ExpectedStringIndexFieldSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $ref: '#/definitions/ExpectedStringIndexField',
    definitions: {
        ExpectedStringIndexField: {
            type: 'object',
            additionalProperties: false,
            properties: {
                $id: { type: 'string' },
                propertyNames: { type: 'object', properties: { pattern: { type: 'string' } }, required: ['pattern'], additionalProperties: false },
                type: { type: 'string', const: 'object' },
                description: { type: 'string' },
                enum: { $ref: '#/definitions/NonEmptyArray%3Cnever%3E' },
                title: { type: 'string' },
                examples: { type: 'array', items: { not: {} } },
                deprecated: { type: 'boolean', const: true },
            },
            required: ['$id', 'propertyNames', 'type'],
        },
        'NonEmptyArray<never>': {
            allOf: [
                { type: 'array', minItems: 0, items: { not: {} } },
                { type: 'array', items: { not: {} } },
            ],
        },
    },
}
export const isExpectedStringIndexField = createValidator<ExpectedStringIndexField>(ExpectedStringIndexFieldSchema, 'isExpectedStringIndexField')
