import type { ReadonlyRecord } from '../../../utils'
import type { ConnectwareError, CybusResourceDeviation, CybusService } from '../../../domain'

import {
    createRSTNonDeviationsCountMapper,
    type DeviationFetcher,
    type JsonResponseContent,
    mapToStatusType,
    type ResourceDeviationSupportedType,
    type ServiceDeviationSupportedType,
} from '../../Connectware'
import { RSTBatchedConnectwareHTTPService, type RSTBatchFetchingStrategy, type RSTFetchingStrategy } from './Base'

type DeviationFetchingArgs = Readonly<{ type: ServiceDeviationSupportedType, serviceId: CybusService['id'] }>
type IsDeviatedBatch = ReadonlyRecord<CybusService['id'], boolean>

/**
 * @ignoreUnneededExport
 */
export class RSTDeviationHTTPService
    extends RSTBatchedConnectwareHTTPService<DeviationFetchingArgs, '/api/v2/resources/states/deviations/count', IsDeviatedBatch, boolean>
    implements DeviationFetcher {
    private readonly mapDeviationsCountData = createRSTNonDeviationsCountMapper<
        JsonResponseContent<'resource-status-tracking', '/api/v2/resources/states/deviations/count'>['data'],
        IsDeviatedBatch
    >((data) => data.reduce<IsDeviatedBatch>((r, { serviceId, count }) => ({ ...r, [serviceId]: Boolean(count) }), {}))

    private readonly isDeviatedInteraction: Omit<
        RSTBatchFetchingStrategy<DeviationFetchingArgs, '/api/v2/resources/states/deviations/count', IsDeviatedBatch>,
        'path' | 'method' | 'capability'
    > = {
        buildQueryParams: (args) => ({ ids: args.map(({ serviceId }) => serviceId), itemsPerPage: `${this.pageSize}` }),
        status: 200,
        mapJsonResponse: ({ data }) => this.mapDeviationsCountData(data),
    }

    /**
     * These strategies here serve two purposes
     * - Allow for the extraction of deviations count to be possible by many means
     * - Enables the scripts that check for endpoint usage (and associate them with capabilities) to detect these endpoints' usage properly
     */
    private readonly isDeviatedStrategies: ReadonlyRecord<
        DeviationFetchingArgs['type'],
        RSTBatchFetchingStrategy<DeviationFetchingArgs, '/api/v2/resources/states/deviations/count', IsDeviatedBatch>
    > = {
        service: { method: 'GET', path: '/api/v2/resources/states/deviations/count', capability: null, ...this.isDeviatedInteraction },
        services: { method: 'GET', path: '/api/v2/resources/states/deviations/count', capability: null, ...this.isDeviatedInteraction },
        serviceDeviations: { method: 'GET', path: '/api/v2/resources/states/deviations/count', capability: null, ...this.isDeviatedInteraction },
        servicesLinks: { method: 'GET', path: '/api/v2/resources/states/deviations/count', capability: null, ...this.isDeviatedInteraction },
    }

    /**
     * These strategies here serve two purposes
     * - Allow for the extraction of resource deviations to be possible by many means
     * - Enables the scripts that check for endpoint usage (and associate them with capabilities) to detect these endpoints' usage properly
     */
    private readonly deviationPageStrategies: ReadonlyRecord<
        ResourceDeviationSupportedType,
        RSTFetchingStrategy<
            [serviceId: CybusService['id'], page: number],
            '/api/v2/resources/states/deviations',
            [deviations: CybusResourceDeviation[], isLast: boolean]
        >
    > = {
        service: {
            method: 'GET',
            path: '/api/v2/resources/states/deviations',
            capability: null,
            /** Page count starts at 1 */
            buildQueryParams: (serviceId, page) => ({ serviceIds: [serviceId], page: `${page + 1}`, itemsPerPage: `${this.pageSize}` }),
            status: 200,
            mapJsonResponse: ({ data, meta: { pagination } }) => [
                data.map(({ resource, info, state }) => ({
                    actual: mapToStatusType(state.current),
                    expected: mapToStatusType(state.desired),
                    resourceId: resource.id,
                    reason: info || null,
                })),
                /** If it is zero, it means that is no more data */
                pagination.nextPage === 0,
            ],
        },
    }

    protected resolveBatchFetchingStrategy (
        args: DeviationFetchingArgs
    ): RSTBatchFetchingStrategy<DeviationFetchingArgs, '/api/v2/resources/states/deviations/count', IsDeviatedBatch> {
        return this.isDeviatedStrategies[args.type]
    }

    // eslint-disable-next-line class-methods-use-this
    protected resolveBatchResponse (result: IsDeviatedBatch, { serviceId }: DeviationFetchingArgs): boolean | ConnectwareError {
        return result[serviceId] ?? false
    }

    fetchIsDeviated (type: ServiceDeviationSupportedType, serviceId: CybusService['id']): Promise<boolean> {
        return this.fetchBatched({ type, serviceId })
    }

    async fetchResourceDeviations (type: ResourceDeviationSupportedType, serviceId: CybusService['id']): Promise<CybusResourceDeviation[]> {
        const { buildQueryParams, status, mapJsonResponse, ...strategy } = this.deviationPageStrategies[type]

        let isDone = false
        let page = 0
        const deviations: CybusResourceDeviation[] = []

        while (!isDone) {
            const [response, isLast] = await this.request({
                ...strategy,
                authenticate: true,
                queryParams: buildQueryParams(serviceId, page),
                handlers: {
                    [status]: (response) =>
                        response.getJson<JsonResponseContent<'resource-status-tracking', (typeof strategy)['path']>>().then(mapJsonResponse),
                },
            })

            deviations.push(...response)
            isDone = isLast
            page++
        }

        return deviations
    }
}
